<template>
	<div class="fa" :style="sty" :class="{'pointer': $route.path === '/'}"  @click="$router.push({name: 'detail', params: { id: curOne?.id }})">
		<div class="cont" :style="{background: `url(${curOne?.img}) lightgray 50% / cover no-repeat`}">
			<div class="s flex">
				<div class="left f1">
					<div class="num">{{ curOne?.year }}</div>
					<div class="title">{{ curOne?.title }}</div>
					<div class="empty"></div>
					<div class="more" v-show="$route.path === '/'">
						Read more
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="40"
							height="40"
							viewBox="0 0 40 40"
							fill="none">
							<path
								d="M5.60001 20.8C5.60001 19.7334 6.13334 19.2 7.20001 19.2L29.6 19.2C30.6667 19.2 31.2 19.7334 31.2 20.8C31.2 21.8667 30.6667 22.4 29.6 22.4L7.20001 22.4C6.13334 22.4 5.60001 21.8667 5.60001 20.8Z"
								fill="#AEAEAE" />
							<path
								d="M31.1024 20.7584L23.164 12.82C22.864 12.5199 22.6955 12.1129 22.6956 11.6885C22.6956 11.2642 22.8643 10.8572 23.1644 10.5572C23.4645 10.2572 23.8715 10.0887 24.2959 10.0888C24.7202 10.0888 25.1272 10.2575 25.4272 10.5576L34.4776 19.608C34.5096 19.64 34.5392 19.672 34.5672 19.7056C34.8348 20.011 34.9762 20.4068 34.9629 20.8126C34.9495 21.2185 34.7823 21.6041 34.4952 21.8912L25.4448 30.9424C25.2962 31.091 25.1198 31.2089 24.9256 31.2893C24.7315 31.3697 24.5234 31.4111 24.3132 31.4111C23.8888 31.4111 23.4817 31.2425 23.1816 30.9424C22.8815 30.6423 22.7129 30.2352 22.7129 29.8108C22.7129 29.3864 22.8815 28.9793 23.1816 28.6792L31.1024 20.7592V20.7584Z"
								fill="#AEAEAE" />
						</svg>
					</div>
				</div>
				<div class="right f1">
					<img
						:src="curOne?.img"
						alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['curOne', 'sty']
}
</script>

<style lang="scss" scoped>
.more {
	align-items: center;
	color: #aeaeae;
	font-size: 16px;
	display: flex;
	svg {
		margin-left: 10px;
		width: 20px;
	}
	&::before {
		content: '';
		display: block;
		flex: 1;
	}
}
.fa {
	height: 50vh;
	background-color: gray;

	.cont {
		padding: 30px;
		right: 0;
		background-position: center;
		background-size: cover;
		left: 0;
		position: absolute;
		width: 100vw;
		height: 50vh;
		background-repeat: no-repeat;
		&::before {
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 50vh;
			/*最后一个参数是半透明度，可以透过调整0-1的数值，调整到满意的透明度*/
			background-color: rgba(0, 0, 0, 0.7);
		}
		.s {
			position: relative;
			height: 100%;
			margin: auto;

			background-color: #121212;
			width: 62.5vw;
			.left {
				flex-direction: column;
				display: flex;
				overflow: hidden;
				padding: 20px;
				align-items: flex-start;
				.num {
					border-radius: 6px;
					background: #fecd45;
					display: inline-block;
					padding: 2px 10px;
					color: #15151d;
					font-family: 'Abril Fatface';
					font-size: 20px;
					margin-bottom: 10px;
				}
				.title {
					color: #fff;
					font-family: 'Abril Fatface';
					font-size: 25px;
					line-height: 45px;
					word-spacing: 2px;
					font-style: normal;
					font-weight: 400;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			.right {
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.empty {
		width: 100%;
		flex: 1;
		
	}
}
</style>